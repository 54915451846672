@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?yl2kzz');
  src:  url('../../fonts/icomoon.eot?yl2kzz#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?yl2kzz') format('truetype'),
    url('../../fonts/icomoon.woff?yl2kzz') format('woff'),
    url('../../fonts/icomoon.svg?yl2kzz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-book:before {
  content: "\e901";
}
.icon-prev:before {
  content: "\e902";
}
.icon-next:before {
  content: "\e903";
}
.icon-device:before {
  content: "\e918";
}
.icon-activation:before {
  content: "\e925";
}
.icon-coupon:before {
  content: "\e926";
}
.icon-home:before {
  content: "\e91f";
}
.icon-search:before {
  content: "\e922";
}
.icon-box:before {
  content: "\e900";
}
.icon-discount:before {
  content: "\e91e";
}
.icon-linkedin:before {
  content: "\e920";
}
.icon-reload:before {
  content: "\e921";
}
.icon-security:before {
  content: "\e923";
}
.icon-twitter:before {
  content: "\e924";
}
