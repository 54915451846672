.contact {
    &Container {
        margin: 30px 0 0 0;
        padding-bottom: 70px;
        background: url('../../images/contact-line.png') no-repeat,  url('../../images/contact-forme.png') no-repeat;
        background-position: -115px 70px, -150px -60px;
    }
    &Left {
        padding: 25px 25px 15px;
        @include border-radius(10px 0 0 10px);
        box-shadow: -50px 0 50px rgba(0,0,0,0.1);
        background: $cWhite;
        @include animation(mapSlide 1.5s);
        &Map {
            margin: 0 0 20px 0;
        }
    }
    &Item {
        &:not(:last-child) {
            margin-bottom: 38px;
        }
        &Title {
            font-size: 2.4rem;
            line-height: 3rem;
            font-weight: 700;
        }
        &Details {
            font-size: 1.6rem;
            line-height: 1.8rem;
            color: $cTextLight;
            strong {
                font-size: 1.8rem;
            }
        }
        &Cta {
            background: $cWhite;
            border: 1px solid $cPrimary;
            color: $cPrimary;
            padding: 7.5px 20px 7.5px;
            &:hover {
                background: $cPrimary;
                color: $cWhite;
                text-decoration: none;
            }
        }
    }
}

@keyframes mapSlide {
    0% {
        margin-left: -50vw;
        // right: -5%;
        // top: -78px;
    }
    100% {
        margin-left: 0;
        // right: -8%;
        // top: -100px;
    }
}
