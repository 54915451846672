.home {
    position: relative;
    background: url('../../images/home-carousel-bg.png') no-repeat;
    // background-size: 874px;
    background-size: 60vw;
    @include animation(float 3s infinite alternate);
    &Header {
        position: relative;
        z-index: 1;
        margin: 40px auto 50px;
        &Social {
            position: absolute;
            left: 0;
            top: 50%;
            @include transform(translateY(-50%));
            &Link {
                font-size: 1.8rem;
                color: $cGrey;
                padding: 9px 20px 9px 25px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                position: relative;
                @include transition(color 0.5s);
                &:before {
                    content: '';
                    background: $cPrimary;
                    @include border-radius(0 20px 20px 0);
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: -68px;
                    top: 0;
                    z-index: 0;
                    @include transition(left 0.5s);
                }
                i {
                    position: relative;
                    z-index: 1;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    color: $cWhite;
                    text-decoration: none;
                    &:before {
                        left: 0;
                    }
                }
            }
        }
        &Carousel {
            opacity: 0;
            &.slick-initialized{
             	opacity: 1;
            }
            .slick-track {
                display: flex;
                align-items: center;
            }
            .slick-dots {
                bottom: -35px;
                li {
                    width: 5px;
                    height: 5px;
                    margin: 0 5px;
                    &.slick-active {
                        button {
                            background: $cPrimary;
                        }
                    }
                    button {
                        width: 5px;
                        height: 5px;
                        background: $cGrey3;
                        padding: 0;
                        &:before {
                            display: none;
                        }
                    }
                }
            }
            &Item {
                display: flex;
                align-items: center;
                & > div {
                    flex: 1;
                    &:first-child {
                        margin-right: 20px;
                        max-width: 435px;
                    }
                }
                &Logo {
                    margin-bottom: 35px;
                    max-width: 150px;
                    display: inline-block !important;
                }
                &Picture {
                    max-height: 455px;
                    // margin: 0 auto;
                }
                &Title {
                    font-family: 'Poppins', sans-serif;
                    font-size: 3.8rem;
                    font-weight: 700;
                    color: $cTextLight4;
                    margin-bottom: 20px;
                }
                p {
                    font-size: 1.8rem;
                    line-height: 3rem;
                }
                .btn-primary {
                    margin-top: 10px;
                }
            }
            &Logo {
                max-width: 110px;
                margin: 0 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                position: relative;
                border: 1px solid transparent;
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                opacity: 0.28;
                @include transition(all 0.2s);
                img {
                    max-height: 65px;
                }
                &:after {
                    content: '';
                    width: 5px;
                    height: 5px;
                    background: $cPrimary;
                    position: absolute;
                    bottom: -15px;
                    left: 50%;
                    @include transform(translateX(-50%));
                    display: none;
                }
                &.active,
                &:hover {
                    border-color: $cPrimary;
                    filter: none;
                    -webkit-filter: none;
                    opacity: 1;
                    &:after {
                        display: block;
                    }
                }
                &Container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 50px;
                }
            }
        }
    }
    &Banner {
        position: relative;
        z-index: 1;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        padding: 40px 0 30px;
        background: rgb(225,16,52);
        background: url('../../images/home-banner-bg.png') no-repeat -50px -35px, linear-gradient(90deg, rgba(225,16,52,1) 0%, rgba(140,10,77,1) 100%);
        &:before {
            content: '';
            height: 66px;
            width: 100%;
            box-shadow: 0 20px 20px rgba(162, 12, 71, 0.4);
            position: absolute;
            top: -66px;
            left: 0;
            z-index: 0;
        }
        &Title {
            font-size: 3.8rem;
            line-height: 4rem;
            font-weight: 700;
            color: $cWhite;
            margin-bottom: 25px;
        }
        &Toggle {
            @include border-radius(50px);
            border: 1px solid $cWhite;
            width: 294px;
            height: 52px;
            padding: 5px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            &Item {
                flex: 1;
                height: 100%;
                &Switch {
                    @include border-radius(30px);
                    border: 0;
                    background: transparent;
                    color: $cWhite;
                    font-size: 1.8rem;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    &:hover {
                        text-decoration: none;
                        color: $cWhite;
                    }
                    &.active {
                        background: $cWhite;
                        color: $cPrimary;
                    }
                }
            }
        }
    }
    &Expert {
        position: relative;
        padding: 65px 0 35px;
        // &:before {
        //     content: 'A';
        //     font-family: 'Poppins', sans-serif;
        //     font-size: 60rem;
        //     line-height: 43rem;
        //     font-weight: 700;
        //     color: $cGrey2;
        //     position: absolute;
        //     left: -93px;
        //     top: 0;
        // }
        &Main {
            &Label {
                font-family: 'Poppins', sans-serif;
                font-size: 1.6rem;
                font-weight: 400;
                color: $cTextLight;
            }
            &Title {
                font-family: 'Poppins', sans-serif;
                font-size: 3.6rem;
                line-height: 4.8rem;
                font-weight: 700;
                margin-bottom: 15px;
            }
            &Details {
                font-size: 1.6rem;
                line-height: 2.4rem;
                margin-bottom: 20px;
            }
            &Illu {
                position: relative;
                &:before {
                    content: '';
                    width: 260px;
                    height: 260px;
                    display: block;
                    border: 2px solid rgba(177, 177, 177, 0.5);
                    @include border-radius(50%);
                    position: absolute;
                    left: 40%;
                    top: 0%;
                    @include transform(translate(-40%, 0%));
                    z-index: 0;
                }
                &Activation,
                &Coupon {
                    display: flex;
                    justify-content: center;
                    z-index: 1;
                    @include transition(all 0.5s);
                    &:hover {
                        @include transform(scale(1.2));
                        text-decoration: none;
                    }
                    &Icon {
                        width: 106px;
                        height: 106px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include border-radius(20px);
                        color: $cWhite;
                        position: relative;
                        z-index: 2;
                    }
                    &Label {
                        font-family: 'Poppins', sans-serif;
                        font-size: 1.8rem;
                        line-height: 2rem;
                        font-weight: 600;
                        position: relative;
                        z-index: 1;
                        span {
                            position: relative;
                        }
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            @include border-radius(20px);
                            box-shadow: 0 40px 50px rgba(0,0,0,0.25);
                            background: $cWhite;
                        }

                    }
                }
                &Activation {
                    &Icon {
                        font-size: 7.8rem;
                        background:-webkit-radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(101, 19, 145) 100%);
                        background:-o-radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(101, 19, 145) 100%);
                        background:-moz-radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(101, 19, 145) 100%);
                        background:radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(101, 19, 145) 100%);
                    }
                    &Label {
                        color: $cBrands;
                        width: 230px;
                        height: 103px;
                        margin-left: -40px;
                        margin-top: -12px;
                        padding-left: 55px;
                        padding-bottom: 28px;
                        display: flex;
                        align-items: flex-end;
                        &:before {
                            opacity: 0.37;
                        }
                    }
                }
                &Coupon {
                    margin-top: 40px;
                    &Icon {
                        font-size: 7.4rem;
                        background:-webkit-radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(253, 94, 31) 100%);
                        background:-o-radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(253, 94, 31) 100%);
                        background:-moz-radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(253, 94, 31) 100%);
                        background:radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(253, 94, 31) 100%);
                    }
                    &Label {
                        color: $cCoupon;
                        text-align: right;
                        width: 244px;
                        height: 96px;
                        margin-right: -68px;
                        margin-top: 28px;
                        padding-right: 80px;
                        padding-bottom: 34px;
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-end;
                        &:before {
                            opacity: 0.3;
                        }
                    }
                }
            }
        }
        &Infos {
            background-image: url('../../images/home-expert-bg.png');
            background-repeat: no-repeat;
            background-position: right -110px top 80px;
            padding: 70px 0 0 calc((100% - 1051px) / 2);
            background-size: 48%;
            margin-top: 60px;
            &Title {
                color: $cTextLight4;
                font-family: 'Poppins', sans-serif;
                font-size: 2.8rem;
                line-height: 3.5rem;
                font-weight: 700;
                margin-bottom: 15px;
            }
            &Details {
                color: $cTextLight;
                font-size: 1.6rem;
                line-height: 2.4rem;
                margin-bottom: 60px;
            }
            &Picture {
                margin-top: -70px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

    }
    .footer {
        position: relative;
        margin-top: 90px;
        &:before {
            content: '';
            position: absolute;
            height: 275px;
            width: 100%;
            top: -225px;
            left: 0;
            background: url('../../images/home-footer-bg.png') no-repeat;
            background-position: -160px 20px;
            background-size: calc(100% + 160px) 100%;
        }
    }
}

@keyframes float {
    0% {
        // background-position: right -10vw top -198px;
        background-position: right -10vw top -13vw;
    }
    100% {
        background-position: right -11vw top -14vw;
        // background-position: right -11vw top -220px;
    }
}
