.news {
    .breadcrumb {
        background: $cBg;
    }
    #content {
        position: relative;
        &:before {
            content: '';
            width: 100%;
            height: 277px;
            position: absolute;
            top: -55px;
            left: 0;
            background: $cBg;
        }
    }
    .pageIntro {
        margin-bottom: 30px;
        font-weight: 400;
        p {
            font-weight: 400;
        }
    }
    &Aside {
        &Item:not(:last-child) {
            margin-bottom: 32px;
        }
        &Book {
            background: url('../../images/book-line.png') no-repeat left top -60px, -webkit-linear-gradient(175deg, rgb(225, 16, 52) 0%, rgb(225, 16, 52) 30%, rgb(140, 10, 77) 100%);
            background: url('../../images/book-line.png') no-repeat left top -60px, -o-linear-gradient(175deg, rgb(225, 16, 52) 0%, rgb(225, 16, 52) 30%, rgb(140, 10, 77) 100%);
            background: url('../../images/book-line.png') no-repeat left top -60px, -moz-linear-gradient(175deg, rgb(225, 16, 52) 0%, rgb(225, 16, 52) 30%, rgb(140, 10, 77) 100%);
            background: url('../../images/book-line.png') no-repeat left top -60px, linear-gradient(175deg, rgb(225, 16, 52) 0%, rgb(225, 16, 52) 30%, rgb(140, 10, 77) 100%);
            width: 100%;
            @include border-radius(10px);
            padding: 20px 25px;
            color: $cWhite;
            margin-bottom: 32px;
            &Title {
                font-family: 'Poppins', sans-serif;
                font-size: 2rem;
                font-weight: 700;
            }
            &Cta {
                @extend .btn;
                background: $cWhite;
                color: $cPrimary;
                text-align: center;
                display: block;
                text-align: center;
                margin-top: 20px;
                &:hover {
                    color: $cPrimaryLight;
                }
            }
            &Icon {
                font-size: 7.3rem;
                line-height: 7rem;
                text-align: right;
            }
        }
        &Title {
            font-family: 'Poppins', sans-serif;
            font-size: 1.6rem;
            font-weight: 700;
            text-transform: uppercase;
            position: relative;
            padding-bottom: 12px;
            margin-bottom: 25px;
            margin-top: 13px;
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40px;
                height: 4px;
                background: $cPrimary;
            }
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: $cTextLight;
                opacity: 0.5;
            }
        }
        &Cats {
            padding: 0;
            margin: 0;
            list-style: none;
            &Item {
                font-size: 1.6rem;
                margin-bottom: 5px;
                &Link {
                    color: $cText;
                    &:hover {
                        text-decoration: none;
                    }
                }
                span {
                    color: $cTextLight;
                }
                &:before {
                    content: '+';
                    color: $cPrimary;
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }
        &Tags {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 0;
            margin: 0;
            list-style: none;
            &Item {
                margin: 0 10px 10px 0;
                &Link {
                    background: rgba(131, 131, 131, 0.1);
                    @include border-radius(50px);
                    font-size: 1.2rem;
                    color: $cTextLight;
                    padding: 4px 15px;
                    &:hover {
                        background: $cPrimary;
                        color: $cWhite;
                        text-decoration: none;
                    }
                }
            }
        }
        &Post {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            &:hover {
                text-decoration: none;
                .newsAsideLastContentTitle {
                    color: $cPrimary;
                }
            }
            &Picture {
                min-width: 76px;
                height: 76px;
                @include border-radius(10px);
                position: relative;
                overflow: hidden;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    @include transform(translate(-50%, -50%));
                }
            }
            &Content {
                margin-left: 10px;
                &Title {
                    font-family: 'Poppins', sans-serif;
                    font-size: 1.15rem;
                    font-weight: 600;
                    margin-bottom: 5px;
                    color: $cText;
                }
                &Date {
                    font-size: 1.15rem;
                    font-weight: 600;
                    color: $cTextLight;
                    margin-bottom: 0;
                }
            }
        }
    }
    &Listing {
        &More {
            text-align: center;
            margin: 10px 0 40px;
            .btn-primary {
                padding-left: 95px;
                padding-right: 95px;
            }
        }
        &Item {
            @include border-radius(10px 10px 0 10px);
            background: $cBg;
            position: relative;
            margin-bottom: 20px;
            height: calc(100% - 20px);
            &Picture {
                width: 100%;
                height: 140px;
                @include border-radius(10px 10px 0 0);
                overflow: hidden;
                position: relative;
                display: block;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    @include transform(translate(-50%, -50%));
                }
            }
            &Date {
                position: absolute;
                z-index: 1;
                top: 140px;
                left: 0;
                width: 140px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $cTextLight;
                @include transform(rotate(-90deg) translateY(-50%));
                transform-origin: top left;
                span {
                    background: $cWhite;
                    padding: 4.5px 10px;
                    @include border-radius(5px);
                    font-size: 1.2rem;
                    font-weight: 600;
                }
            }
            &Content {
                padding: 15px 10px 25px 10px;
                &Cat {
                    color: $cPrimary;
                    font-size: 1.2rem;
                    margin-bottom: 10px;
                    font-family: 'Poppins', sans-serif;
                }
                &Title {
                    font-size: 1.8rem;
                    line-height: 2rem;
                    font-weight: 500;
                    margin-bottom: 10px;
                    font-family: 'Poppins', sans-serif;
                }
                &Details {
                    font-size: 1.4rem;
                    line-height: 2rem;
                    color: $cTextLight;
                }
                &Link {
                    font-size: 1.4rem;
                    font-weight: 700;
                    color: $cPrimary;
                }
            }
        }
    }
    &Post {
        &Banner {
            position: relative;
            height: 280px;
            margin-bottom: 30px;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                @include transform(translate(-50%, -50%));
            }
        }
        &Title {
            font-family: 'Poppins', sans-serif;
            font-size: 3.8rem;
            line-height: 4.5rem;
            font-weight: 700;
            margin-bottom: 20px;
        }
        &Cat {
            color: $cPrimary;
            font-size: 1.8rem;
            font-family: 'Poppins', sans-serif;
            margin-bottom: 5px;
        }
        &Content {
            font-size: 1.8rem;
            line-height: 2.8rem;
            padding-right: 20px;
            p {
                margin-bottom: 30px;
            }
        }
        &Nav {
            display: flex;
            align-items: stretch;
            margin-top: 50px;
            &Item {
                flex: 1;
                @include border-radius(10px);
                box-shadow: 0 20px 20px rgba(0,0,0,0.1);
                padding: 20px;
                @include transition(0.5s all);
                &:hover {
                    text-decoration: none;
                    @include transform(scale(1.02));
                }
                &:first-child {
                    margin-right: 20px;
                    .newsPostNavItemIcon {
                        margin-right: 10px;
                    }
                }
                &:last-child {
                    margin-left: 20px;
                    .newsPostNavItemIcon {
                        margin-left: 10px;
                    }
                }
                &Header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                }
                &Cat {
                    color: $cPrimary;
                    font-size: 1.2rem;
                    font-family: 'Poppins', sans-serif;
                }
                &Title {
                    font-family: 'Poppins', sans-serif;
                    font-size: 1.8rem;
                    line-height: 2rem;
                    font-weight: 500;
                    color: $cText;
                }
                &Icon {
                    font-size: 2rem;
                    color: $cTextLight;
                }
            }
        }

    }
}
