.search {
    &Container {
        position: absolute;
        top: 90px;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(219, 219, 219, 0.96);
        z-index: 10;
        display: none;
    }
    &Content {
        margin: 55px auto;
        padding: 0 15px;
        max-width: 715px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    &Close {
        width: 27px;
        height: 30px;
        cursor: pointer;
        margin-bottom: 20px;
        &:after,
        &:before {
            content: '';
            background-color: $cText;
            @include border-radius(3px);
            display: block;
            height: 2px;
            margin: 7px 0;
            @include transition(all .2s ease-in-out);
        }
        &:before {
            @include transform(translateY(12px) rotate(135deg));
        }
        &:after {
            @include transform(translateY(3px) rotate(-135deg));
        }
    }
    &Form {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        height: 61px;
        max-width: 715px;
        width: 100%;
        position: relative;
        &Input {
            flex: 4;
            @include border-radius(50px 0 0 50px);
            border: 0;
            background: $cWhite;
            padding: 0 45px;
            font-size: 1.6rem;
        }
        &Submit {
            flex: 1;
            @include border-radius(0 50px 50px 0);
            background: $cPrimaryLight;
            color: $cWhite;
            font-weight: 700;
            border: 0;
            text-align: center;
            font-size: 2rem;
        }
    }
    &Results {
        background: url('../../images/search-bg.png') no-repeat;
        background-position: center top;
        background-size: 100% 140px;
        .footer {
            margin-top: 0;
        }
        .breadcrumb {
            margin-top: 5px;
        }
        &Title {
            font-family: 'Poppins', sans-serif;
            font-size: 1.8rem;
            font-weight: 700;
            margin: 30px auto 20px;
        }
        &Container {
            padding-left: 70px;
            padding-right: 20px;
            min-height: calc(100vh - 482px);
        }
        &Item {
            background: $cBg;
            @include border-radius(10px);
            padding: 20px;
            margin-bottom: 12px;
            position: relative;
            &:last-child {
                margin-bottom: 0;
            }
            &.post {
                padding-left: 40px;
            }
            &Cat {
                color: $cPrimary;
                font-size: 1.2rem;
                margin-bottom: 3px;
            }
            &Title {
                font-family: 'Poppins', sans-serif;
                font-size: 1.6rem;
                font-weight: 500;
                a {
                    color: $cText;
                    &:hover {
                        color: $cPrimary;
                        text-decoration: none;
                    }
                }
            }
            &Details {
                color: $cTextLight;
                margin-bottom: 0;
                a {
                    color: $cPrimary;
                    font-weight: bold;
                    display: block;
                    margin-top: 5px;
                    &:hover {
                        color: $cPrimary;
                        text-decoration: underline;
                    }
                }
            }
            &Date {
                @extend .newsListingItemDate;
                top: 132px;
                width: 132px;
            }
        }
    }
}
