$cPrimary: #DD193B;
$cPrimaryLight: #EA3441;
$cPrimaryLight2: #E73746;
$cBrands: #651391;
$cBrandsDark: #5C058B;
$cCoupon: #FD5E1A;
$cRetailers: #AC1488;
$cRetailersDark: #AA1E87;
$cDiscount: #FD5E1F;
$cDiscountLight: #FA5F2E;
$cText: #161616;
$cTextLight: #838383;
$cTextLight2: #707070;
$cTextLight4: #4F4E4E;
$cBorder: #DEE2E6;
$cGrey: #DBDBDB;
$cGrey2: #EDEDED;
$cGrey3: #CDCDCD;
$cBg: #F6F6F6;
$cWhite: #FFFFFF;
