.header {
    margin-top: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    position: relative;
    z-index: 10;
    &Container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 80px;
    }
    &Logo {
        img {
            width: 180px;
        }
    }
    &Social {
        font-size: 2.2rem;
        display: none;
        &Link {
            color: $cPrimary;
            margin-right: 10px;
            display: inline-block;
            &:hover {
                color: $cPrimaryLight;
                text-decoration: none;
            }
        }
    }
    &Nav {
        &Mobile {
            width: 27px;
            display: none;
            cursor: pointer;
            z-index: 2;
            &:after,
            &:before,
            div {
                content: '';
                background-color: $cText;
                @include border-radius(3px);
                display: block;
                height: 2px;
                margin: 7px 0;
                @include transition(all .2s ease-in-out);
            }
            &.opened {
                &:before {
                    @include transform(translateY(10px) rotate(135deg));
                }
                &:after {
                    @include transform(translateY(-8px) rotate(-135deg));
                }
                div {
                    @include transform(scale(0));
                }
            }
        }
        &List {
            margin: 0;
            padding: 0;
            display: flex;
            list-style: none;
            &Item {
                margin: 0 20px;
                padding: 20px 0;
                position: relative;
                &Link {
                    color: $cText;
                    position: relative;
                    display: inline-block;
                    &:after {
                        content: '';
                        width: 5px;
                        height: 5px;
                        background: $cPrimary;
                        position: absolute;
                        bottom: -7px;
                        left: 0%;
                        opacity: 0;
                        @include transition(all 0.5s);
                    }
                    &:hover,
                    &.hover,
                    &.current {
                        text-shadow: 0px 0px 1px $cPrimary;
                        color: $cPrimary;
                        text-decoration: none;
                        &:after {
                            opacity: 1;
                            left: 50%;
                            @include transform(translateX(-50%));
                        }
                    }
                    &.current {
                        text-shadow: none;
                        font-weight: 600;
                    }
                }
            }
            &Sub {
                position: absolute;
                width: 265px;
                left: -5px;
                top: 60px;
                background: $cWhite;
                box-shadow: 0 30px 40px rgba(0,0,0,0.2);
                list-style: none;
                padding: 30px 35px;
                left: 0;
                @include border-radius(0 0 10px 10px);
                z-index: 10;
                display: none;
                &Item {
                    margin-bottom: 10px;
                    &Link {
                        color: $cText;
                        font-family: 'Poppins', sans-serif;
                        line-height: 2.1rem;
                        &:hover,
                        &.current {
                            font-weight: 500;
                            color: $cPrimaryLight2;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
