.portfolio {
    position: relative;
    z-index: 1;
    &Title {
        font-family: 'Poppins', sans-serif;
        font-size: 2.4rem;
        line-height: 3.5rem;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .row {
        margin-right: -10px;
        margin-left: -10px;
    }
    div[class^=col] {
        max-width: inherit;
        padding-left: 10px;
        padding-right: 10px;
    }
    &Cta {
        margin-top: 20px;
        text-align: center;
    }
    &Carousel {
        width: 100%;
        opacity: 0;
        &.slick-initialized{
            opacity: 1;
        }
        .slick-track {
            min-width: 100%;
        }
        &Item {
            height: 248px;
            overflow: hidden;
            position: relative;
            display: block;
            @include border-radius(10px);
            &.listing {
                margin-bottom: 20px;
            }
            &:hover {
                .portfolioCarouselItemInfos {
                    opacity: 1;
                }
            }
            &Picture {
                object-fit: cover;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
            &Infos {
                position: absolute;
                z-index: 1;
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                left: 20px;
                top: 20px;
                background: rgba(255,255,255,0.9);
                @include border-radius(10px);
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-family: 'Poppins', sans-serif;
                padding: 20px;
                overflow: hidden;
                opacity: 0;
                @include transition(all 0.5s);
                &Name {
                    font-size: 2.4rem;
                    line-height: 2.4rem;
                    font-weight: 700;
                    color: $cText;
                    text-align: center;
                }
                &Cat {
                    font-size: 1.4rem;
                    font-weight: 600;
                    color: $cBrands;
                }
            }
        }
    }
    &Details {
        &Header {
            margin-bottom: 20px;
            .pageTitle {
                margin-bottom: 20px;
            }
        }
        &Infos {
            margin-bottom: 50px;
            &Title {
                font-family: 'Poppins', sans-serif;
                font-size: 2.8rem;
                line-height: 3.5rem;
                font-weight: 700;
                margin-bottom: 15px;
                &Sub {
                    font-size: 1.8rem;
                    line-height: 2rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                }
            }
            &Left {
                padding-right: 50px;
            }
            p {
                font-size: 1.6rem;
                line-height: 2rem;
                color: $cTextLight;
            }
        }
        &More {
            background: $cBg;
            @include border-radius(8px);
            padding: 25px;
            margin-bottom: 40px;
            text-align: center;
            p {
                font-size: 1.8rem;
                line-height: 2rem;
                margin-bottom: 0;
                color: $cTextLight;
            }
            .btn-primary {
                margin-top: 20px;
                padding: 8.5px 74px 8.5px;
            }
        }
    }
}
