@media (min-width: 576px) {
    .homeHeader .container {
        max-width: 440px;
    }
}
@media (min-width: 768px) {
    .homeHeader .container {
        max-width: 640px;
    }
}
@media (min-width: 992px) {
    .homeHeader .container {
        max-width: 860px;
    }
    .modal-dialog {
        max-width: 850px;
    }
}
@media (min-width: 1200px) {
    .homeHeader .container {
        max-width: 1060px;
    }
    .container {
        max-width: 1051px;
    }
    .header .container {
        max-width: 1140px;
    }
}

@media screen and (max-width: 1599px) {
    .home {
        background-size: 65vw;
    }
}

@media screen and (max-width: 1199px) {
    .home {
        &Expert {
            &Infos {
                background-size: 58%;
                background-position-y: center;
                padding: 70px 0 0 calc((100% - 960px) / 2);
                &Picture {
                    margin-top: -30px;
                }
            }
        }
        &HeaderCarousel {
            &Logo {
                margin: 0 5px;
            }
        }
    }
    .portfolioCarouselItem {
        height: 225px;
        &InfosName {
            font-size: 2rem;
            line-height: 2rem;
        }
    }
    .expertDetailMain {
        &Left {
            padding-left: calc((100% - 930px) / 2);
        }
        &Right {
            &Title {
                padding-left: calc(100% - (930px / 2) + 15px);
            }
        }
    }
    .about {
        &Banner {
            &Header {
                padding-left: calc((100% - 930px) / 2);
            }
            &Title {
                max-width: 55%;
            }
            &Item {
                &Container {
                    margin: -180px auto 0;
                }
            }
        }
        &Actor {
            &Logo {
                justify-content: flex-start;
                margin-bottom: 20px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .modal {
        &-content {
            padding: 30px 50px 15px;
        }
    }
    .header {
        margin-top: 0;
        &Social {
            width: 690px;
            margin: 0 auto 20px;
            display: flex;
        }
        &Nav {
            position: absolute;
            left: 0;
            top: 80px;
            width: 100%;
            height: calc(100vh - 80px);
            overflow: hidden;
            background: $cBg;
            display: none;
            overflow-y: auto;
            &List {
                width: 690px;
                margin: 0 auto;
                padding: 20px 0;
                &Item {
                    padding: 15px 0;
                    margin: 0;
                }
                &Sub {
                    background: none;
                    position: relative;
                    box-shadow: none;
                    top: inherit;
                    left: inherit;
                    @include border-radius(0);
                    width: 100%;
                    padding: 30px 0 0 35px;
                }
            }
            &List {
                flex-direction: column;
            }
            &Mobile {
                display: block;
                &Container {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                }
            }
        }
    }
    .home {
        background-size: 95vw;
        // &:before {
        //     width: 100vw;
        // }
        &Banner {
            &Title {
                font-size: 3rem;
                line-height: 3.6rem;
            }
        }
        &Expert {
            &MainIlluActivation:hover,
            &MainIlluCoupon:hover {
                @include transform(scale(1.1));
            }
            &Infos {
                padding: 70px 0 0 0;
                max-width: 720px;
                margin: 0 auto;
                background: none;
            }
        }
        &HeaderCarousel {
            &Logo {
                max-width: 70px;
            }
            &Item {
                flex-direction: column-reverse;
                align-items: flex-start;
                & > div {
                    width: 100%;
                    &:last-child {
                        margin-bottom: 20px;
                    }
                    &:first-child {
                        margin-right: 0;
                    }
                }
                &Title {
                    font-size: 3rem;
                }
                &Logo {
                    max-width: 120px;
                    margin-bottom: 10px;
                    &Container {
                        margin-top: 0;
                    }
                }
            }
        }
        .footer {
            margin-top: 50px;
            &:before {
                background-position: 80% 20px;
                top: -195px;
            }
        }
    }
    .portfolioCarouselItem {
        height: 164px;
        &Infos {
            &Name {
                font-size: 1.8rem;
                line-height: 1.8rem;
            }
            &Cat {
                font-size: 1.3rem;
            }
        }
    }
    .news {
        #content:before {
            display: none;
        }
        .breadcrumb {
            background: $cWhite;
        }
        &Aside {
            max-width: 80%;
            margin: 0 auto;
        }
        &Post {
            &Content {
                padding-right: 0;
            }
            &Nav {
                margin-bottom: 50px;
            }
        }
    }
    .contact {
        &Container {
            background-position: left -150px bottom 150px, center bottom -70vw;
            background-size: 70vw, 170vw;
            margin-bottom: -40px;
        }
        &Left {
            margin-top: 40px;
            .contactItem {
                margin-left: 10px;
            }
        }
    }
    .expert {
        &Cat {
            margin: 0 20px;
            &Container {
                background: url('../../images/expert-bg-perso.png') no-repeat, url('../../images/expert-bg-forme.png') no-repeat;
                background-position: 80% top, center 130px;
                background-size: auto, cover;
            }
        }
        &DetailMain {
            padding-bottom: 0;
            &:before {
                top: 80px;
            }
            &Left {
                padding-left: calc((100% - 690px) / 2);
                &Picture {
                    margin-top: -50px;
                }
            }
            &Right {
                max-width: 690px;
                margin: 0 auto;
                &Title {
                    padding: 0;
                }
                &Picture {
                    padding-left: 0;
                }
                &Item {
                    &Container {
                        margin-top: -60px;
                        padding: 104px 0px 113px;
                    }
                }
            }
        }
    }
    .about {
        &Banner {
            &Header {
                padding-left: calc((100% - 690px) / 2);
                padding-right: calc((100% - 690px) / 2);
            }
            &Title {
                max-width: 100%;
                padding-right: 0;
                line-height: 3.4rem;
            }
            &Item {
                &.client {
                    right: -20px;
                    top: 320px;
                }
                &.age {
                    bottom: 240px;
                }
                &.team {
                    left: 75px;
                    top: 210px;
                }
                &.group {
                    bottom: 100px;
                }
                &Container {
                    margin-top: 20px;
                    width: 690px;
                    height: 700px;
                }
            }
        }
        &Partner {
            &List {
                flex-wrap: wrap;
                &Item {
                    max-width: 38%;
                    margin: 0 20px;
                    flex: auto;
                }
            }
        }
        &Team {
            flex-direction: column-reverse;
            padding-right: 0;
            &Infos {
                min-height: auto;
                margin-bottom: 25px;
                padding-bottom: 40px;
                @include border-radius(0);
            }
            &Carousel {
                width: 100%;
                overflow: hidden;
                &Item {
                    height: 38.6vw;
                }
            }
        }
    }
    .footer {
        &Newsletter {
            margin-bottom: 30px;
        }
        &ExpertItem.expertises-item .footerExpertItemLink {
            display: none;
        }
        &Pages {
            &Social {
                margin-top: 30px;
            }
        }
    }
    .search {
        &Container {
            top: 112px;
        }
        &Results {
            &Container {
                padding: 0;
            }
            .footer {
                margin-top: 40px;
            }
            background-size: 100% 130px;
        }
    }
    @keyframes float {
        0% {
            background-position: 255% -28px;
            // right: -15%;
            // top: -78px;
        }
        100% {
            background-position: 260% -50px;
            // right: -18%;
            // top: -100px;
        }
    }
}

@media screen and (max-width: 767px) {
    .header {
        &Social {
            width: 510px;
        }
        &Nav {
            &List {
                width: 510px;
            }
        }
    }
    .home {
        &Expert {
            &Infos {
                padding: 50px 0 0;
                max-width: 540px;
                margin: 0 auto;
                &Details {
                    margin-bottom: 40px;
                }
            }
            &MainLabel {
                margin-top: 60px;
            }
        }
        &Header {
            &Carousel {
                &LogoContainer {
                    display: none;
                }
            }
        }
    }
    .expert {
        &Cat {
            &:first-child {
                margin-bottom: 130px;
            }
            &Container {
                flex-direction: column;
                align-items: center;
                background-size: auto, auto 600px;
                &:after {
                    display: none;
                }
            }
        }
        &Portfolio {
            margin-top: 0;
        }
        &DetailMain {
            &Left {
                padding: 0;
                &Infos {
                    max-width: 510px;
                    margin: 0 auto;
                }
                &Picture {
                    text-align: right;
                    margin-bottom: 30px;
                }
            }
            &Right {
                max-width: 510px;
                margin: 0 auto;
            }
        }
    }
    .portfolioCarouselItem {
        height: 185px;
        &.listing {
            height: 386px;
        }
        &Infos {
            &Name {
                font-size: 1.8rem;
                line-height: 1.8rem;
            }
            &Cat {
                font-size: 1.2rem;
            }
        }
    }
    .about {
        &Banner {
            &Header {
                padding-left: calc((100% - 510px) / 2);
                padding-right: calc((100% - 510px) / 2);
            }
            &Picture {
                position: relative;
                top: inherit;
                right: inherit;
                align-self: flex-end;
                @include border-radius(10px 0 0 10px);
            }
            &Item {
                position: relative;
                &.age {
                    left: inherit;
                    bottom: inherit;
                    @include border-radius(0 10px 10px 0);
                    margin-top: -20px;
                    width: 70%;
                }
                &.team {
                    left: inherit;
                    top: inherit;
                    align-self: flex-end;
                    margin-top: -20px;
                    width: calc(100% - 65px);
                    margin-right: 30px;
                    padding: 40px 20px 25px;
                }
                &.client {
                    right: inherit;
                    top: inherit;
                    @include border-radius(10px 0 0 10px);
                    align-self: flex-end;
                    margin-top: -20px;
                    width: calc(100% - 20px);
                    padding: 50px;
                }
                &.group {
                    right: inherit;
                    bottom: inherit;
                    margin-top: -20px;
                    margin-right: 10px;
                    align-self: flex-end;
                    width: 80%;
                }
                &Container {
                    width: 100%;
                    margin-top: 20px;
                    margin-bottom: 80px;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        &Actor {
            &Item {
                margin-bottom: 30px;
            }
        }
    }
    .newsPostNav {
        flex-direction: column;
        &Item {
            &:first-child,
            &:last-child {
                margin: 10px 0;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    body.scroll .modal-dialog {
        margin-top: 55px;
    }
    .modal {
        &-content {
            padding: 30px 20px 15px;
            .btn-primary {
                padding: 8.5px 51px 8.5px;
            }
        }
    }
    .breadcrumb {
        font-size: 1.1rem;
    }
    .container, .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }
    .row {
        margin-right: -20px;
        margin-left: -20px;
        div[class^="col"] {
            padding-right: 20px;
            padding-left: 20px;
        }
    }
    .header {
        &Social {
            width: 100%;
            padding: 0 15px;
        }
        &Logo img {
            width: 130px;
        }
        &Container {
            min-height: 63px;
        }
        &Nav {
            top: 63px;
            height: calc(100vh - 63px);
            &List {
                width: 100%;
                padding: 20px 15px;
            }
        }
    }
    .contact {
        &Left {
            padding: 15px 0 25px 15px;
        }
    }
    .news {
        &ListingMore {
            .btn-primary {
                padding-left: 30px;
                padding-right: 30px;
                display: block;
            }
        }
    }
    .search {
        &Container {
            top: 63px;
        }
        &Form {
            &Input {
                padding: 0 10px 0px 30px;
            }
        }
        &Results {
            background-position: -198px top;
            background-size: 230% 110px;
        }
    }
    .portfolio {
        .row {
            margin-right: -20px;
            margin-left: -20px;
        }
        &Carousel {
            width: calc(100% + 40px);
            overflow: hidden;
            padding-left: 15px;
            div[class^=col] {
                max-width: inherit;
                padding-left: 7.5px;
                padding-right: 7.5px;
            }
            &Item {
                height: 44vw;
                &.listing {
                    height: 67.575vw;
                }
            }
        }
        &DetailsHeader {
            margin-left: -20px;
            width: calc(100% + 40px);
            .pageTitle {
                padding: 0 20px;
            }
        }
    }
    .home {
        &Header {
            margin: 10px auto 70px;
            &Social {
                display: none;
            }
            &CarouselItem {
                &Title {
                    font-size: 2.8rem;
                }
                &Picture {
                    max-height: 205px;
                }
                p {
                    font-size: 1.4rem;
                    line-height: 2rem;
                }
            }
        }
        &Banner {
            background: url('../../images/home-banner-bg.png') no-repeat -310px -35px, linear-gradient(90deg, #e11034 0%, #8c0a4d 100%);
            padding: 70px 0 50px;
            &Title {
                font-size: 2.8rem;
                line-height: 3.5rem;
            }
            &Toggle {
                max-width: 100%;
            }
        }
        &Expert {
            &Infos {
                &Picture {
                    display: none;
                }
            }
            &:before {
                left: -200px;
            }
        }
    }
    .page {
        &Title {
            margin-bottom: 30px;
            font-size: 3.5rem;
        }
        &Intro, &Intro p {
            font-weight: 400;
        }
    }
    .expert {
        &Cat {
            &Container {
                background-size: 90% auto, auto 600px;
                background-position: right -225% top, center 130px;
                padding-top: 120px;
            }
        }
        &Detail {
            &Main {
                background-size: 95%, 180% calc(100% - 80px);
                background-position: right -120px top 180px, center 80px;
                &.brand {
                    &:before {
                        right: -140px;
                    }
                }
                &:before {
                    font-size: 27.6rem;
                    top: 0;
                }
                &Left {
                    &Picture {
                        padding-left: 15%;
                    }
                }
                &RightItemInfosTitle {
                    font-size: 2rem;
                    line-height: 2.4rem;
                }
            }
            &Logo {
                margin: 10px 20px;
            }
        }
    }
    .about {
        &Title {
            margin-bottom: 25px;
        }
        &Banner {
            &Header {
                padding-left: 20px;
            }
            &Picture {
                width: 75%;
                height: 50vw;
            }
        }
        &Partner {
            &List {
                &Item {
                    max-width: 100%;
                    margin: 0 0 20px 0;
                }
            }
        }
        &Team {
            &Infos {
                padding: 40px 20px;
            }
            &Carousel {
                &Item {
                    height: 48.8vw;
                }
            }
        }
    }
    .footer {
        &Sub {
            margin-top: 35px;
            display: block;
            &Item {
                margin-bottom: 5px;
                &:after {
                    display: none;
                }
            }
        }
    }
}
