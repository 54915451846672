html {
    font-size: 62.5%;
    height: 100%;
}

body {
    color: $cText;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    &.no {
        overflow: hidden;
    }
    &.scroll {
        .modal {
            height: 100%;
            top: 0px;
            &-backdrop {
                top: 0;
            }
            &-dialog {
                margin-top: 115px;
            }
        }
    }
}

a {
    color: $cPrimary;
    &:hover {
        color: $cPrimary;
    }
}

.btn {
    font-family: 'Poppins', sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    color: $cWhite;
    border: 0;
    @include border-radius(20px);
    padding: 8.5px 39px 8.5px;
    &-primary {
        background: $cPrimary;
        &:hover,
        &:focus {
            background: $cPrimaryLight;
        }
    }
}

.modal {
    height: calc(100% - 90px);
    top: 90px;
    &Title {
        font-family: 'Poppins', sans-serif;
        font-size: 2.4rem;
        line-height: 3.5rem;
        font-weight: 600;
        text-align: center;
        margin-bottom: 15px;
    }
    &Infos {
        font-size: 1.6rem;
        line-height: 2rem;
        color: $cTextLight;
        text-align: center;
        margin-bottom: 15px;
    }
    &-backdrop {
        background: rgba(219, 219, 219, 0.96);
        top: 90px;
        &.show {
            opacity: 1;
        }
    }
    &-dialog {
        margin-top: 25px;
        .close {
            @extend .searchClose;
            opacity: 1;
            cursor: pointer;
        }
    }
    &-content {
        border: 0;
        @include border-radius(10px);
        background: $cWhite;
        box-shadow: 0 40px 50px rgba(0,0,0,0.25);
        padding: 30px 70px 15px;
        label {
            display: block;
        }
        input[type="text"], input[type="email"], input[type="number"], select, textarea {
            width: 100%;
            border: 1px solid $cBorder;
            @include border-radius(4px);
            background: $cWhite;
            height: 38px;
            margin-bottom: 10px;
            padding: 0 15px;
            &:focus {
                outline: none;
                border-color: $cPrimary;
            }
        }
        textarea {
            height: 100px;
            padding: 15px;
        }
        .customSelect {
            position: relative;
            margin-top: 5px;
            margin-bottom: 20px;
            &:after {
                content: '›';
                @include transform(translateY(-50%) rotate(90deg));
                position: absolute;
                top: 50%;
                right: 20px;
                z-index: 1;
                pointer-events: none;
                font-size: 3rem;
                letter-spacing: -0.019em;
            }
        }
        .customCheckbox {
            position: absolute;
            opacity: 0;
            &Container {
                display: inline-block;
            }
            & + label {
                position: relative;
                cursor: pointer;
                padding: 0;
            }
            // Box.
            & + label:before {
                content: '';
                margin-right: 10px;
                display: inline-block;
                vertical-align: text-top;
                border: 2px solid $cBorder;
                @include border-radius(4px);
                width: 18px;
                height: 18px;
                background: $cWhite;
            }
            // Checkmark. Could be replaced with an image
            &:checked + label:after {
                content: '';
                position: absolute;
                left: 4px;
                top: 9px;
                background: white;
                width: 2px;
                height: 2px;
                box-shadow:
                  2px 0 0 $cPrimary,
                  4px 0 0 $cPrimary,
                  4px -2px 0 $cPrimary,
                  4px -4px 0 $cPrimary,
                  4px -6px 0 $cPrimary,
                  4px -8px 0 $cPrimary;
                transform: rotate(45deg);
            }
        }
        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin-bottom: 0;
        }
        ::placeholder {
            color: $cGrey;
            font-style: italic;
        }
        .btn-primary {
            margin-top: 15px;
            padding: 8.5px 81px 8.5px
        }
        .required {
            margin-top: 10px;
            color: $cGrey;
            font-style: italic;
        }
    }
}

.dropdown {
    font-family: 'Poppins', sans-serif;
    &-item {
        font-size: 1.2rem;
        padding: 10px 20px;
        &:hover {
            background: none;
            color: $cPrimary;
        }
    }
    &-menu {
        padding: 10px 0;
        @include border-radius(0 0 10px 10px);
        box-shadow: 0 30px 40px rgba(0,0,0,0.2);
        border: 0;
    }
    &-toggle {
        color: $cPrimary;
        padding: 0;
        display: inline-flex;
        align-items: center;
        position: relative;
        margin-bottom: 35px;
        &:hover,
        &:focus {
            color: $cPrimaryLight;
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }
        &:before {
            content: '';
            width: 5px;
            height: 5px;
            background: $cPrimary;
            position: absolute;
            bottom: -7px;
            left: 50%;
            @include transform(translateX(calc(-50% - 7px)));
        }
        &:after {
            content: ">";
            font-size: 1.2rem;
            font-weight: 600;
            color: $cText;
            @include transform(rotate(90deg));
            border: 0;
            margin: 0 0 0 10px;
        }
    }
}

.breadcrumb {
    background: transparent;
    padding: 0;
    @include border-radius(0);
    color: rgb(22, 22, 22, 0.5);
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 10px;
    margin-top: 15px;
    position: relative;
    z-index: 1;
    & > a {
        margin-right: 5px;
        color: rgb(0, 0, 0, 0.5);
        font-size: 1.4rem;
    }
    span, a {
        display: inline-flex;
        align-items: center;
    }
    span.breadcrumb_last {
        margin-left: 5px;
    }
    a {
        color: rgb(22, 22, 22, 0.5);
        &:after {
            content: '›';
            font-size: 2rem;
            font-weight: 200;
            margin-left: 5px;
            display: inline-block;
        }
        &:hover {
            color: $cPrimary;
            text-decoration: none;
            &:after {
                color: rgb(22, 22, 22, 0.5);
            }
        }
    }
}

.nav {
    margin-bottom: 40px;
    align-items: center;
    .nav-item {
        font-family: 'Poppins', sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        &.wm {
            margin-right: 30px;
            &:last-child {
                margin-right: 0;
            }
        }
        .nav-link {
            color: $cText;
            position: relative;
            padding: 0;
            &:after {
                content: '';
                width: 5px;
                height: 5px;
                background: $cPrimary;
                position: absolute;
                bottom: -7px;
                left: 0%;
                opacity: 0;
                @include transition(all 0.5s);
            }
            &.active,
            &:hover {
                color: $cPrimary;
                text-decoration: none;
                &:after {
                    opacity: 1;
                    left: 50%;
                    @include transform(translateX(-50%));
                }
            }
            &.active {
                font-size: 1.4rem;
            }
        }
    }
}

.page {
    &Title {
        font-family: 'Poppins', sans-serif;
        font-size: 3.8rem;
        line-height: 4rem;
        font-weight: 700;
        margin-bottom: 40px;
        &:after {
            content: '';
            width: 45px;
            height: 5px;
            background: $cText;
            display: block;
            margin-top: 15px;
        }
        &.brand {
            color: $cBrands;
            &:after {
                background: $cBrands;
            }
        }
        &.retailer {
            color: $cRetailersDark;
            &:after {
                background: $cRetailersDark;
            }
        }
        &.discount {
            color: $cDiscount;
            &:after {
                background: $cDiscount;
            }
        }
        &.about, &.contact {
            color: $cPrimary;
            &:after {
                background: $cPrimary;
            }
        }
    }
    &Intro,
    &Intro p {
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-weight: 600;
    }
}
