@import "~bootstrap/scss/bootstrap";

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import './abstract/mixins';
@import './abstract/helpers';
@import './abstract/variables';

@import './global/font';
@import './global/base';

@import './layout/footer';
@import './layout/header';

@import './pages/portfolio';

@import './pages/home';
@import './pages/expert';
@import './pages/about';
@import './pages/contact';
@import './pages/news';

@import './global/search';

@import './global/responsive';
