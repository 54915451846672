.about {
    &Title {
        color: $cPrimary;
        font-family: 'Poppins', sans-serif;
        font-size: 2.8rem;
        line-height: 4rem;
        font-weight: 700;
        margin-bottom: 40px;
    }
    &Banner {
        background: url('../../images/about-bg-line.png') no-repeat, url('../../images/about-bg-forme.png') no-repeat;
        background-position: right bottom, center;
        min-height: 667px;
        position: relative;
        @include animation(floatingBg 3s infinite alternate);
        &Header {
            padding-left: calc((100% - 1021px) / 2);
        }
        &Title {
            font-family: 'Poppins', sans-serif;
            font-size: 2.8rem;
            line-height: 4rem;
            font-weight: 700;
            padding-right: 40px;
            max-width: 50%;
        }
        &Picture {
            @include border-radius(10px);
            overflow: hidden;
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            width: 413px;
            height: 276px;
            box-shadow: -30px 35px 30px rgba(0,0,0,0.16);
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        &Item {
            @include border-radius(10px);
            box-shadow: 0 40px 50px rgba(0,0,0,0.25);
            padding: 25px 30px;
            color: $cTextLight;
            display: flex;
            justify-content: center;
            flex-direction: column;
            font-family: 'Poppins', sans-serif;
            position: absolute;
            @include transition(transform 0.5s);
            cursor: pointer;
            &:hover {
                @include transform(scale(1.05));
                z-index: 3 !important;
            }
            &.age {
                background: $cPrimary;
                color: $cWhite;
                font-size: 5.4rem;
                font-weight: 700;
                width: 263px;
                text-align: center;
                left: 0;
                bottom: 85px;
                z-index: 0;
                padding: 35px;
            }
            &.team {
                background: $cWhite;
                width: 335px;
                text-align: center;
                padding-top: 40px;
                left: 175px;
                top: 210px;
                z-index: 2;
            }
            &.client {
                background: $cPrimary;
                color: $cWhite;
                width: 462px;
                padding: 50px 50px 50px 75px;
                right: 60px;
                top: 250px;
                z-index: 1;
                .aboutBannerItemTitle {
                    color: $cWhite;
                    font-size: 3.4rem;
                }
            }
            &.group {
                background: $cWhite;
                width: 400px;
                text-align: center;
                right: 270px;
                bottom: 30px;
                z-index: 2;
            }
            &Container {
                position: relative;
                width: 980px;
                height: 550px;
                margin: -180px 10% 0 auto;
            }
            &Title {
                font-size: 2.8rem;
                font-weight: 700;
                color: $cPrimary;
                margin-bottom: 0;
            }
            &Details {
                font-size: 1.8rem;
                line-height: 2rem;
                margin-bottom: 0;
                font-weight: 500;
            }
        }
    }
    &Actor {
        &Item {
            margin-bottom: 60px;
        }
        &Logo {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &Details {
            font-size: 1.6rem;
            line-height: 2.5rem;
            color: $cTextLight;
        }
    }
    &Partner {
        &List {
            display: flex;
            align-items: center;
            justify-content: center;
            &Item {
                flex: 1;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                &:hover {
                    .aboutPartnerListItemLogo {
                        opacity: 1;
                        -webkit-filter: none;
                        filter: none;
                    }
                }
                &Details {
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    color: $cTextLight;
                    margin-top: 20px;
                }
                &Logo {
                    max-width: 110px;
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                    opacity: 0.28;
                    @include transition(all 0.2s);
                    height: 65px;
                    display: flex;
                    align-items: center;
                    img {
                        max-height: 65px;
                    }
                }
            }
        }
    }
    &Team {
        display: flex;
        align-items: center;
        padding-right: calc((100% - 1021px) / 2 - 60px);
        margin-top: 60px;
        &Carousel {
            width: 55%;
            &Item {
                height: 247px;
                @include border-radius(10px);
                overflow: hidden;
                position: relative;
                margin: 0 10px;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }
        &Infos {
            @include border-radius(10px);
            background: $cWhite;
            box-shadow: -50px 0 50px rgba(0,0,0,0.1);
            padding: 60px;
            min-height: 402px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            &Title {
                font-size: 2.8rem;
                line-height: 3rem;
                font-weight: 700;
                margin-bottom: 15px;
            }
            &Details {
                font-size: 1.6rem;
                line-height: 2.5rem;
                color: $cTextLight;
            }
        }
    }
}

@keyframes floatingBg {
    0% {
        background-size: auto, auto 580px;
    }
    100% {
        background-size: auto, auto 665px;
    }
}
