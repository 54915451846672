.expert {
    &Cat {
        width: 415px;
        max-width: 100%;
        background: $cWhite;
        box-shadow: 0 12px 30px rgba(0,0,0,0.1);
        @include border-radius(20px);
        margin: 0 30px;
        position: relative;
        padding: 100px 45px 20px;
        &:hover {
            .expertCatIcon {
                @include transform(translateX(-50%) scale(1.1));
            }
        }
        &Container {
            display: flex;
            justify-content: center;
            padding: 170px 20px 60px;
            background: url('../../images/expert-bg-line.png') no-repeat, url('../../images/expert-bg-perso.png') no-repeat, url('../../images/expert-bg-forme.png') no-repeat;
            background-position: right -140px top 90px, 80% top, center 130px;
            background-size: auto, auto, cover;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                background: url('../../images/expert-bg-forme-end.png') no-repeat;
                background-size: 100% 100%;
                bottom: -141px;
                left: 0;
                width: 100%;
                height: 141px;
            }
        }
        &.activation {
            .expertCat {
                &Title {
                    color: $cBrandsDark;
                    &:after {
                        background: $cBrandsDark;
                    }
                }
                &Icon {
                    font-size: 11.6rem;
                    background:-webkit-radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(101, 19, 145) 100%);
                    background:-o-radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(101, 19, 145) 100%);
                    background:-moz-radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(101, 19, 145) 100%);
                    background:radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(101, 19, 145) 100%);
                }
            }
        }
        &.coupon {
            .expertCat {
                &Title {
                    color: $cDiscount;
                    &:after {
                        background: $cDiscount;
                    }
                }
                &Icon {
                    font-size: 11rem;
                    background:-webkit-radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(253, 94, 31) 100%);
                    background:-o-radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(253, 94, 31) 100%);
                    background:-moz-radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(253, 94, 31) 100%);
                    background:radial-gradient(circle farthest-corner at bottom right, rgb(219, 30, 63) 0%, rgb(253, 94, 31) 100%);
                }
                &Cta {
                    background: $cDiscount;
                }
            }
        }
        &Icon {
            width: 157px;
            height: 157px;
            position: absolute;
            left: 50%;
            top: -80px;
            @include transform(translateX(-50%));
            @include border-radius(20px);
            @include transition(all 0.5s);
            box-shadow: 20px 15px 30px rgba(0,0,0,0.16);
            display: flex;
            align-items: center;
            justify-content: center;
            color: $cWhite;
        }
        &Title {
            font-family: 'Poppins', sans-serif;
            font-size: 2.8rem;
            line-height: 3rem;
            font-weight: 600;
            position: relative;
            margin-bottom: 14px;
            &:after {
                content: '';
                width: 45px;
                height: 5px;
                display: block;
                margin: 12px 0 0 0;
            }
        }
        &Details {
            line-height: 2.4rem;
            color: $cTextLight4;
            margin-bottom: 15px;
        }
        &Cta {
            margin-bottom: 15px;
            &:hover {
                color: $cWhite;
                opacity: 0.9;
            }
            &.brands {
                background: $cBrands;
                text-transform: uppercase;
                margin-right: 15px;
            }
            &.retailers {
                background: $cRetailers;
                text-transform: uppercase;
            }
        }
    }
    &Detail {
        &Main {
            background: url('../../images/expert-detail-bg-line.png') no-repeat, url('../../images/expert-detail-bg-forme.png') no-repeat;
            background-size: 685px 480px, 100% calc(100% - 80px);
            background-position: right -120px top 240px, center 80px;
            position: relative;
            overflow-x: hidden;
            margin-top: -80px;
            padding-top: 140px;
            padding-bottom: 80px;
            // &:before {
            //     font-family: 'Poppins', sans-serif;
            //     font-size: 50rem;
            //     line-height: 35rem;
            //     font-weight: 700;
            //     position: absolute;
            //     top: 0px;
            //     color: $cTextLight4;
            //     opacity: 0.1;
            // }
            &.brand {
                // &:before {
                //     content: 'M';
                //     right: -160px;
                // }
                .expertDetailMain {
                    &LeftCta {
                        background: $cBrands;
                    }
                    &RightItem {
                        &Container:before {
                            background:-webkit-linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(101, 19, 145) 30%, rgb(101, 19, 145) 60%, rgb(255, 255, 255) 100%);
                            background:-o-linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(101, 19, 145) 30%, rgb(101, 19, 145) 60%, rgb(255, 255, 255) 100%);
                            background:-moz-linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(101, 19, 145) 30%, rgb(101, 19, 145) 60%, rgb(255, 255, 255) 100%);
                            background:linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(101, 19, 145) 30%, rgb(101, 19, 145) 60%, rgb(255, 255, 255) 100%);
                        }
                        &Icon {
                            color: $cBrands
                        }
                    }
                }
            }
            &.retailer {
                // &:before {
                //     content: 'R';
                //     right: -50px;
                // }
                .expertDetailMain {
                    &LeftCta {
                        background: $cRetailers;
                    }
                    &RightItem {
                        &Container:before {
                            background:-webkit-linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(172, 20, 136) 30%, rgb(172, 20, 136) 60%, rgb(255, 255, 255) 100%);
                            background:-o-linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(172, 20, 136) 30%, rgb(172, 20, 136) 60%, rgb(255, 255, 255) 100%);
                            background:-moz-linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(172, 20, 136) 30%, rgb(172, 20, 136) 60%, rgb(255, 255, 255) 100%);
                            background:linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(172, 20, 136) 30%, rgb(172, 20, 136) 60%, rgb(255, 255, 255) 100%);
                        }
                        &Icon {
                            color: $cRetailers
                        }
                    }
                }
            }
            &.discount {
                &:before {
                    content: 'C';
                    right: -80px;
                }
                .expertDetailMain {
                    &LeftCta {
                        background: $cDiscount;
                    }
                    &RightItem {
                        &Container:before {
                            background:-webkit-linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(253, 94, 31) 30%, rgb(253, 94, 31) 60%, rgb(255, 255, 255) 100%);
                            background:-o-linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(253, 94, 31) 30%, rgb(253, 94, 31) 60%, rgb(255, 255, 255) 100%);
                            background:-moz-linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(253, 94, 31) 30%, rgb(253, 94, 31) 60%, rgb(255, 255, 255) 100%);
                            background:linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(253, 94, 31) 30%, rgb(253, 94, 31) 60%, rgb(255, 255, 255) 100%);
                        }
                        &Icon {
                            color: $cDiscount
                        }
                    }
                }
            }
            &Left {
                padding-left: calc((100% - 1021px) / 2);
                padding-right: 30px;
                &Title {
                    font-family: 'Poppins', sans-serif;
                    font-size: 2.8rem;
                    line-height: 3.5rem;
                    font-weight: 700;
                    margin-bottom: 15px;
                }
                &Details {
                    font-size: 1.6rem;
                    line-height: 2.5rem;
                    color: $cTextLight;
                }
                &Cta {
                    margin-bottom: 50px;
                    &:hover {
                        color: $cWhite;
                        opacity: 0.9;
                    }
                }
                &Picture {
                    margin-top: -100px;
                }
            }
            &Right {
                &Title {
                    @extend .expertDetailMainLeftTitle;
                    color: $cTextLight2;
                    padding-left: calc(100% - (1021px / 2) + 15px);
                }
                &Picture {
                    padding-left: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &Item {
                    display: flex;
                    margin-bottom: 30px;
                    position: relative;
                    z-index: 1;
                    &Container {
                        position: relative;
                        padding: 104px 70px 113px 0;
                        &:before {
                            content: '';
                            width: 2px;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 39px;
                            z-index: 0;
                            opacity: 0.2;
                        }
                    }
                    &Icon {
                        min-width: 80px;
                        height: 80px;
                        @include border-radius(50%);
                        background: $cWhite;
                        box-shadow: 0 10px 30px rgba(0,0,0,0.1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 4rem;
                    }
                    &Infos {
                        margin-left: 20px;
                        padding-top: 25px;
                        &Title {
                            font-family: 'Poppins', sans-serif;
                            font-size: 2.4rem;
                            line-height: 3.5rem;
                            font-weight: 600;
                        }
                        &Details {
                            font-size: 1.6rem;
                            line-height: 2.5rem;
                            color: $cTextLight;
                        }
                    }
                }
            }
        }
        &Logo {
            margin: 10px 30px;
            max-width: 110px;
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            opacity: 0.28;
            @include transition(all 0.2s);
            img {
                max-height: 65px;
            }
            &:hover {
                opacity: 1;
                -webkit-filter: none;
                filter: none;
            }
            &Container {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                margin-bottom: 40px;
                margin-top: 10px;
            }
        }
    }
}
