.footer {
    margin-top: 40px;
    &Container {
        background: $cBg;
        padding-top: 40px;
    }
    &Logo {
        width: 120px;
        margin-bottom: 10px;
    }
    &Infos {
        color: $cTextLight;
        font-size: 1rem;
        line-height: 1.7rem;
        margin-bottom: 0;
    }
    &Newsletter {
        margin-top: 30px;
        &Label {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            margin-bottom: 5px;
        }
        &Form {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            height: 34px;
            max-width: 293px;
            width: 100%;
            &Input {
                flex: 3;
                @include border-radius(50px 0 0 50px);
                border: 0;
                background: $cWhite;
                padding: 0 20px;
                font-size: 1.2rem;
            }
            &Submit {
                flex: 1;
                @include border-radius(0 50px 50px 0);
                background: $cPrimaryLight;
                color: $cWhite;
                font-weight: 700;
                border: 0;
                text-align: center;
                padding: 0 15px;
            }
        }
    }
    &Expert {
        list-style: none;
        padding: 0;
        margin: 0;
        &Item {
            margin-bottom: 10px;
            &.expertises-item .footerExpertItemLink {
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 400;
                color: $cTextLight2;
                font-size: 1.2rem;
                line-height: 1.2rem;
                display: flex;
                &:hover {
                    opacity: 0.8;
                }
                &:before {
                    content: '›';
                    margin-right: 5px;
                }
            }
            &Link {
                color: $cText;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                span {
                    color: $cTextLight;
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    &Pages {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        &List {
            list-style: none;
            padding: 0;
            margin: 0;
            &Item {
                margin-bottom: 10px;
                &Link {
                    color: $cText;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    span {
                        color: $cTextLight;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        &Social {
            font-size: 2.2rem;
            margin-bottom: 20px;
            &Link {
                color: $cPrimary;
                margin-right: 10px;
                display: inline-block;
                &:hover {
                    color: $cPrimaryLight;
                    text-decoration: none;
                }
            }
        }
    }
    &News {
        background: $cWhite;
        @include border-radius(10px 10px 0 0);
        padding: 10px 20px;
        &Title {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            margin-bottom: 9px;
        }
        &Item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            &Picture {
                width: 40px;
                height: 40px;
                @include border-radius(10px);
                overflow: hidden;
                margin-right: 15px;
                flex: 40px 0 0;
                position: relative;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                }
            }
            &Details {
                font-family: 'Poppins', sans-serif;
                font-size: 1.2rem;
                line-height: 1.4rem;
                font-weight: 600;
                margin-bottom: 0;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
    &Sub {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0 0 20px 0;
        margin-top: 8px;
        &Item {
            &:after {
                content: '|';
                color: $cTextLight;
                margin: 0 20px;
                opacity: 0.8;
            }
            &:last-child:after {
                display: none;
            }
            &Link {
                color: $cTextLight;
                font-size: 1rem;
                opacity: 0.8;
                &:hover {
                    color: $cTextLight;
                }
            }
        }
    }
}
